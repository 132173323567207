import React from "react";
import cn from "classnames";

const RatingVariantWay = ({variants = [], onChange}) => {
    return (
        <>
            <ul className={cn('variants')}>
                {variants.map((variant, key) => {
                    return <RatingVariantWayItem variant={variant} onChange={onChange} key={key}/>
                })}
            </ul>
        </>
    )
}

const RatingVariantWayItem = ({variant, onChange}) => {
    return <>
        <li
            className={cn('item', {'active': variant.active})}
            key={variant.key}
            onClick={() => {
                onChange(variant.value)
            }}
        >{variant.title}</li>
    </>
}

export default  RatingVariantWay;