import React from "react";
import { Checkbox } from "primereact/checkbox";
import cn from "classnames";

const Agreement = ({agreement = false, setAgreement = () => {}, error= false}) => {
    return (
        <div className={cn('agreement')}>
            {error && <div className={cn('errors__wrapper')}>Необходимо ваше согласие на обработку персональных данных</div>}
            <div className={'checkbox__wrapper'}>
                <Checkbox
                    className={cn({'error': error})}
                    onChange={(e) => setAgreement(e.checked)}
                    checked={agreement}
                    id="agreement"
                    name="agreement"
                />
                <label htmlFor="agreement" className="p-checkbox-label">
                    Я согласен(-а) на обработку персональных данных <br />
                    в соответствии с <a href="/files/OKReview_privacy_policy.pdf" target="_blank"
                                        rel="noreferrer"
                >политикой конфиденциальности</a><br />
                    и <a href="/files/OKReview_terms_of_use.pdf" target="_blank"
                         rel=" noreferrer"
                >пользовательским соглашением</a>
                </label>
            </div>
        </div>
    );
}

export default Agreement;
