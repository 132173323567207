import React from "react";
import { SelectButton } from "primereact/selectbutton";
import cn from "classnames";

const Nps = ( {nps = '', npsOptions= [], onChange = () => {}, error = false, disabled = false}) => {

    const npsTemplate = (option) => `${option.value}`;

    return (
        <>
            <div className={'nps'}>
                <SelectButton
                    value={nps}
                    options={npsOptions}
                    onChange={(e) => onChange(e.value)}
                    itemTemplate={npsTemplate}
                    disabled={disabled}
                />
                <div className={'nps__legend'}>
                    <span>Точно нет</span>
                    <span className={'dotted'}>..................................</span>
                    <span>Обязательно</span>
                </div>
            </div>
            {error && <div className={cn('errors__wrapper')}>Поле обязательно для заполнения</div>}
        </>
    )
}
export default Nps;
