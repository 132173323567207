import React, { useEffect, useState } from "react";
import cn from "classnames";
import { ProgressSpinner } from "primereact/progressspinner";
import ExternalReview from "components/common/ExternalReview";
import Footer from "components/common/Footer";
import { mainService, api } from "services";
import {getParam} from "./helpers";
import Header from "./common/Header";
import { AdplatformsSecondHeader } from "./tmp_folder/Templates";
import FormFooter from "./common/FormFooter";


const SecondForm = ({template = 'default'}) => {
    const utm = getParam();

    const [comment, setComment] = useState('');
    const [showDest, setShowDest] = useState(false);
    const [loading, setLoading] = useState(false);
    const [adplatforms, setAdplatforms] = useState([]);
    const [branch, setBranch] = useState('');

    const { destOptions } = mainService;

    useEffect(() => {
        if(utm) {
            api.getClientInfo(utm)
                .then((result) => {
                    result.data && setBranch(result.data.branch)
                })
        }

        document.querySelector('meta[property="og:title"]').content = 'Оставьте отзыв о компании'
        document.querySelector('meta[property="og:description"]').content = 'Оставьте отзыв о компании'
        document.title = 'Оставьте отзыв о компании';

        fetch(`${process.env.REACT_APP_API_URL}/review/${utm}`, { method: 'GET' })
            .then(response => response.json())
            .then(result => {
                let tmp = [];
                // eslint-disable-next-line array-callback-return
                if(result.data) {
                    result.data?.adplatforms.forEach(item => {
                        const tmp2 = destOptions.find((dest) => dest.platform === item.type);
                        if (typeof tmp2 !== 'undefined') {
                            tmp.push({
                                ...tmp2,
                                url: item.url
                            })
                        }
                    });

                    tmp.sort((a, b) => a.sort > b.sort ? 1 : -1 );

                    setAdplatforms(tmp);

                    const comment = `${result.data.text} ${result.data.customer.firstname[0].toUpperCase()}. ${result.data.customer.lastname[0].toUpperCase()}.`

                    setComment(comment);
                    copyToClipboard(comment);

                }

                setLoading(false);
                setShowDest(true);
            })

    // eslint-disable-next-line
    }, []);

    const copyToClipboard = (text) => {
        let textarea = document.createElement('textarea');
        document.body.appendChild(textarea);
        textarea.value = text;
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
    }

    return (
        <>
            <Header template={template}/>
            <div className={cn('bg__wrapper')}>
                <div className={cn('container')}>
                    <div className={cn('form')}>
                        <div className={cn('form__header')}>
                            <h1 className={cn('header', 'header__text')}>Благодарим за отзыв:<br/> остался всего один шаг</h1>
                        </div>
                        <div className={cn('form__container')}>
                            {loading && <div className={cn('loader')} ><ProgressSpinner/></div>}

                            {showDest && <div className={'external-review__wrapper'}>
                                <div className={cn('external-review')}>
                                    <AdplatformsSecondHeader template={template} branch={branch}/>
                                    {adplatforms && <ExternalReview
                                        utm={utm}
                                        comment={comment}
                                        adplatforms={adplatforms} />
                                    }
                                </div>
                            </div>}
                            {showDest && <div className={'element__wrapper'}>
                                <h3>Текст вашего отзыва (уже в буфере обмена)</h3>
                                <div className={cn('textarea__wrapper')}>
                                <textarea
                                    name="message"
                                    id="message"
                                    value={comment}
                                    placeholder='Напишите ваш отзыв...'
                                    disabled={true}
                                />
                                </div>
                            </div>}
                            <div className={cn('question')}>
                                Возник вопрос? Наш контакт: <a href="mailto:info@okreview.ru">info@okreview.ru</a>
                            </div>
                        </div>
                        <FormFooter template={template} />
                    </div>
                </div>
                <Footer template={template} />
            </div>
        </>
    );

}

export default SecondForm;
