import React from 'react';
import cn from "classnames";
import Salon from "../../images/block4_ico2.svg";
import People from "../../images/block4_ico1.svg";

const FormFooter = ({ template = 'default' }) => {
    return (
        <>
            {template === 'default' &&
                <div className={cn('img__wrapper')}>
                    <img src={Salon} alt="" className={'img-salon'}/>
                    <img src={People} alt="" className={'img-people'}/>
                </div>}
            {template === 'common' &&
                <div className={cn('img__wrapper')}>
                    <img src={People} alt="" className={cn('img-people', 'common')}/>
                </div>
            }
        </>
    )
}

export default FormFooter;