import React from "react";
import cn from "classnames";
import { reachGoal } from "components/YandexMetrika";

const ExternalReview = ({utm= null, comment= '', adplatforms = [] }) => {
    const copyToClipboard = (text) => {
        reachGoal('copy2go_event');

        let textarea = document.createElement('textarea');

        document.body.appendChild(textarea);
        textarea.value = text;
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
    }

    const sendSomething = (platform) => {
        let data = new FormData();
        data.append('application', utm);
        data.append('adplatform', platform);
        navigator.sendBeacon(`${process.env.REACT_APP_API_URL}/review/ad-tracker`, data);
    }

    return (
        <div className={cn('external-review__images')}>
            <div className={'adplatforms'}>
                {adplatforms.map((item, key) =>
                    (
                        <a
                            aria-label={item.url}
                            key={key}
                            href={item.url}
                            className={cn('item', item.class)}
                            onClick={() => {
                                sendSomething(item.platform);
                                copyToClipboard(comment);
                            }}
                            target="_blank"
                            rel="noreferrer"
                        />
                    )
                )}
            </div>
        </div>
    )
}

export default ExternalReview;
