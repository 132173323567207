import { useLocation, useParams } from "react-router";

const params = ['utm_term', 'id', 'utm'];

const getParam = () => {
    if(useLocation().search !== '') {
        const param = params.filter(param => {
            return new URLSearchParams(useLocation().search).get(param);
        })[0]
        return new URLSearchParams(useLocation().search).get(param);
    }
    else{
        const {utm} = useParams();
        return utm;
    }
}

export {
    getParam,
}