import cn from "classnames";
import React from "react";

const  AdplatformsSecondHeader = ({template = 'default', branch = ''}) => {
    const text = !!branch ? `для ${branch}` : ``;
    return (
        <>
            { template === 'default' &&
                <>
                    <h3>Будем признательны, если Вы разместите Ваш отзыв {text} на одной из площадок</h3>
                    <div className={'external-review__subtext'}>Выберите площадку, чтобы перейти на нее. Ваш
                        отзыв уже скопирован - просто вставьте его в поле для отзыва на площадке.
                    </div>
                </>
            }
            { template === 'ao_medicina' &&
                <>
                    <h3>Будем признательны, если Вы разместите Ваш отзыв на одной из площадок</h3>
                    <div className={'external-review__subtext'}>Выберите площадку, чтобы перейти на нее. Ваш
                        отзыв уже скопирован - просто вставьте его в поле для отзыва на площадке.
                    </div>
                </>
            }
            { template === 'avtoset_rf' &&
                <>
                    <h3>Будем признательны, если Вы разместите Ваш отзыв {text} на одной из площадок</h3>
                    <div className={'external-review__subtext'}>Выберите площадку, чтобы перейти на нее. Ваш
                        отзыв уже скопирован - просто вставьте его в поле для отзыва на площадке.
                    </div>
                </>
            }

        </>
    )
}

const  AdplatformsHeader = ({template = 'default', branch = ''}) => {
    const text = !!branch ? `для ${branch}` : ``;
    return (
        <>
            { template === 'default' &&
                <>
                    <h3>Вам всё понравилось и Вы нашли время оставить отзыв {text}, мы это очень ценим!</h3>
                    <div className={'external-review__subtext'}>Будем признательны, если Вы напишете отзыв на одном из сайтов:</div>
                </>
            }
            { template === 'ao_medicina' &&
                <>
                    <h3>Вам всё понравилось и Вы нашли время оставить отзыв, мы это очень ценим!</h3>
                    <div className={'external-review__subtext'}>Будем признательны, если Вы напишете отзыв на одном из сайтов:</div>
                </>
            }
            { template === 'avtoset_rf' &&
                <>
                    <h3>Вам всё понравилось и Вы нашли время оставить отзыв {text}, мы это очень ценим!</h3>
                    <div className={'external-review__subtext'}>Будем признательны, если Вы напишете отзыв на одном из сайтов:</div>
                </>
            }

        </>
    )
}

const FormH3Header = ({template = 'default'}) => {
    return (
        <>
            { template === 'common' &&
                <h3>Оцените ваши впечатления и качество обслуживания</h3>
            }
            { template === 'default' &&
                <h3>Оцените впечатление от покупки и обслуживания</h3>
            }
            { template === 'ao_medicina' &&
                <h3>Оцените ваши впечатления и качество обслуживания</h3>
            }
            { template === 'avtoset_rf' &&
                <h3>Оцените ваши впечатления и качество обслуживания</h3>
            }
        </>
    )
}

const FormHeader = ({template = 'default', branch= ''}) => {
    return (
        <>
            { template === 'common' &&
                <div className={cn('form__header')}>
                    <h1 className={cn('header', 'header__text')}>Благодарим за выбор<br />{branch}{branch && '!'}</h1>
                    <div className={'header__subtext'}>
                        Ваши впечатления крайне важны для сотрудников компании и могут помочь другим клиентам
                        сделать правильный выбор
                    </div>
                </div>
            }

            { template === 'default' &&
                <div className={cn('form__header')}>
                    {!!branch ?
                        <h1 className={cn('header', 'header__text')}>Благодарим за выбор<br />{branch}{branch && '!'}</h1> :
                        <h1 className={cn('header', 'header__text')}>Поздравляем с покупкой!</h1>
                    }
                    <div className={'header__subtext'}>
                        Ваши впечатления крайне важны для нас и могут помочь другим клиентам сделать правильный выбор!
                    </div>
                </div>
            }
            { template === 'ao_medicina' &&
                <div className={cn('form__header')} style={{padding: '40px 25px'}}>
                    <h1 className={cn('header', 'header__text')}>
                        <span style={{whiteSpace: 'nowrap'}}>Благодарим за выбор</span><br/>Клиника академика Ройтберга!
                    </h1>
                    <div className={'header__subtext'} style={{fontSize: '14px', textAlign:'center', padding: '0 20px'}} >
                        Ваши впечатления крайне важны для сотрудников компании и могут помочь другим клиентам сделать правильный выбор
                    </div>
                </div>
            }
            { template === 'avtoset_rf' &&
                <div className={cn('form__header')}>
                    <h1 className={cn('header', 'header__text')}>
                        Благодарим за выбор
                    </h1>
                    <div className={'header__subtext'} style={{fontSize: '14px', textAlign:'center', padding: '0 20px'}} >
                        Ваши впечатления крайне важны для сотрудников компании и могут помочь другим клиентам сделать правильный выбор
                    </div>
                </div>
            }

        </>
    )
}

const AdviceText = ({template = 'default', branch = ''}) => {
    const text = !!branch ? branch : `автодилера`;

    return (
        <>
            { template === 'common' &&
                <h3>Какова вероятность, что Вы порекомендуете <nobr>{text}</nobr> своим друзьям и знакомым?</h3>
            }
            { template === 'default' &&
                <h3>Какова вероятность, что Вы порекомендуете <nobr>{text}</nobr> своим друзьям и знакомым?</h3>
            }
            { template === 'ao_medicina' &&
                <h3>Какова вероятность, что Вы порекомендуете
                    Стоматологии академика Ройтберга своим друзьям
                    и знакомым?</h3>
            }
            { template === 'avtoset_rf' &&
                <h3>Какова вероятность, что Вы порекомендуете своим друзьям и знакомым?</h3>
            }

        </>
    )
}

const ClaimAcceptedText = ({template = 'default'}) => {
    return (
        <>
            { template === 'default' &&
                <div className={'thanks__text'} style={{ paddingTop:'0'}}>
                    <div>
                        <h1>Спасибо <span className={'no-br'}>за ваш отзыв!</span></h1>
                        <p>Благодарим Вас за обратную связь, мы это очень ценим.</p>
                        <p>Информация будет передана руководству на рассмотрение и в ближайшее время будут обязательно приняты меры.</p>
                    </div>
                </div>
            }
            { template === 'ao_medicina' &&
                <div className={'thanks__text'} style={{paddingTop:'0'}}>
                    <div>
                        <h1>Спасибо <span className={'no-br'}>за ваш отзыв!</span></h1>
                        <p>Благодарим Вас за обратную связь, мы это очень ценим.</p>
                        <p>Информация будет передана руководству на рассмотрение и в ближайшее время будут обязательно приняты меры.</p>
                    </div>
                </div>
            }
            { template === 'avtoset_rf' &&
                <div className={'thanks__text'} style={{ paddingTop:'0'}}>
                    <div>
                        <h1>Спасибо <span className={'no-br'}>за ваш отзыв!</span></h1>
                        <p>Благодарим Вас за обратную связь, мы это очень ценим.</p>
                        <p>Информация будет передана руководству на рассмотрение и в ближайшее время будут обязательно приняты меры.</p>
                    </div>
                </div>
            }

        </>
    )

}

export {FormHeader, AdviceText, ClaimAcceptedText, FormH3Header, AdplatformsHeader, AdplatformsSecondHeader}