//TODO почему такое огромное лого? надо уменьшить
import default_thanks_img from "../images/thanks.png"
import ao_medicin_thanks_img from "../images/templates/ao_medicina/thanks.png";
import avtoset_rf_thanks_img from "../images/thanks.png";

import default_logo from "../images/header_logo.png";
import ao_medicin_logo from "../images/templates/ao_medicina/header_logo.svg";
import avtoset_rf_logo from "../images/templates/avtoset_rf/header_logo.png";



const logoState = [
    {code: 'default', src: default_logo, height: '37px'},
    {code: 'ao_medicina', src: ao_medicin_logo, height: '74px'},
    {code: 'avtoset_rf', src: avtoset_rf_logo, height: '74px'},
];

const thanksImagesState = [
    {code: 'default', src: default_thanks_img},
    {code: 'ao_medicina', src: ao_medicin_thanks_img},
    {code: 'avtoset_rf', src: avtoset_rf_thanks_img},
];

function getThanksImage(code) {
    return thanksImagesState.find(i => i.code === code);
}

function getLogo(code) {
    return logoState.find(l => l.code === code);
}

export {getLogo, getThanksImage};