import React, { useEffect, useState } from "react";
import cn from "classnames";
import { api, mainService } from "services";

import { Rating } from "primereact/rating";
import { Button } from "primereact/button";
import { useHistory } from "react-router";
import { ProgressSpinner } from "primereact/progressspinner";
import { getParam } from "components/helpers/index";
import Agreement from "components/common/Agreement";
import Footer from "components/common/Footer";
import ExternalReview from "components/common/ExternalReview";
import Nps from "components/common/Nps";
import Header from "./common/Header";
import {AdviceText, FormH3Header, FormHeader} from "./tmp_folder/Templates";
import '../styles/main.scss';
import '../styles/templates.scss';
import FormFooter from "./common/FormFooter";

const MainForm = ({template = 'default'}) => {
    const utm = getParam();

    let history = useHistory();

    const [comment, setComment] = useState('');
    const [rating, setRating] = useState(0);
    const [nps, setNps] = useState(0);
    const [showDest, setShowDest] = useState(false);
    const [agreement, setAgreement] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [adplatforms, setAdplatforms] = useState([]);
    const [backErrors, setBackErrors] = useState([]);
    const [branch, setBranch] = useState('');
    const [errors, setErrors] = useState({
        comment: false,
        rating: false,
        nps: false,
        agreement: false,
    });

    const { npsOptions, destOptions, prepareHeader } = mainService;

    useEffect(() => {

        if(utm) {
            api.getClientInfo(utm)
                .then((result) => {
                    result.data && setBranch(result.data.branch)
                })
        }

        document.querySelector('meta[property="og:title"]').content = 'Оставьте отзыв о компании'
        document.querySelector('meta[property="og:description"]').content = 'Оставьте отзыв о компании'
        document.title = 'Оставьте отзыв о компании'
    }, [])

    useEffect(() => {
        setIsValid((
            !!comment.trim().length &&
            !!rating &&
            !!agreement
        ));
    }, [comment, rating, agreement]);

    const validateForm = () => {
        setErrors({
            comment: !comment.trim().length,
            rating: !rating,
            agreement: !agreement,
        });
        return isValid;
    }

    const onSubmit = () => {

        if (!validateForm()) return false;

        setLoading(true);

        fetch(`${process.env.REACT_APP_API_URL}/review`,
            prepareHeader({
                "rating": rating,
                "nps_score": nps,
                "comment": comment,
                "publication": "Анонимно",
                "pd_agreement": "yes",
                "utm_term": utm,
            }))
            .then(response => response.json())
            .then(result => {
                if (result.statusCode === 200) {
                    setBackErrors([]);
                    fetch(`${process.env.REACT_APP_API_URL}/review/${utm}`, { method: 'GET' })
                        .then(response => response.json())
                        .then(result => {
                            let tmp = [];
                            // eslint-disable-next-line array-callback-return

                            if (result.data) {
                                const comment = `${result.data.text} ${result.data.customer.firstname[0].toUpperCase()}. ${result.data.customer.lastname[0].toUpperCase()}.`
                                setComment(comment);
                                result.data.adplatforms.forEach(item => {
                                    const tmp2 = destOptions.find((dest) => dest.platform === item.type);
                                    if (typeof tmp2 !== 'undefined') {
                                        tmp.push({
                                            ...tmp2,
                                            url: item.url
                                        })
                                    }
                                });

                                tmp.sort((a, b) => a.sort > b.sort ? 1 : -1);

                                setAdplatforms(tmp);

                            }

                            setLoading(false);
                            if (rating > 3) {
                                setShowDest(true);
                            } else {
                                history.push(`/claim_accepted`)
                            }
                        })
                }

                if (result.statusCode === 422 && !!result.error.data.length) {
                    let errorArray = []
                    result.error.data.map((error) => errorArray.push(error.message));
                    setBackErrors(errorArray);
                }
            })
            .catch(error => console.log('error', error))
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <>
            <Header template={template}/>
            <div className={cn('bg__wrapper')}>
            <div className={cn('container')}>
                <div className={cn('form')}>
                    <FormHeader template={template} />
                    <div className={cn('form__container')}>
                        {loading && <div className={cn('loader')}><ProgressSpinner/></div>}
                        <div className={'element__wrapper'}>
                            <FormH3Header template={template} />

                            <div className={cn('rating__wrapper')}>
                                <Rating
                                    className={'rating'}
                                    value={rating}
                                    cancel={false}
                                    onChange={(e) => setRating(e.value)}
                                    disabled={showDest}
                                />
                                {errors.rating && <div className={cn('errors__wrapper')}>Поставьте вашу оценку</div>}
                            </div>
                        </div>
                        <div className={'element__wrapper'}>
                            <h3>Что понравилось, а что можно улучшить?</h3>
                            <div className={cn('textarea__wrapper', { 'error': errors.comment })}>
                                <textarea
                                    name="message"
                                    id="message"
                                    value={comment}
                                    placeholder='Напишите ваш отзыв...'
                                    disabled={showDest}
                                    onChange={(e) => setComment(e.target.value)}
                                />
                            </div>
                            {errors.comment &&
                                <div className={cn('errors__wrapper')}>Поле обязательно для заполнения</div>}
                            {!!backErrors.length && <div className={cn('errors__wrapper')}>
                                {backErrors.map((error, key) => (<div key={key}>{error}</div>))}
                            </div>}
                        </div>
                        <div className={'element__wrapper'}>
                            <AdviceText template={template} branch={branch}/>
                            {/*<h3>Какова вероятность, что Вы порекомендуете автодилера своим друзьям и знакомым?</h3>*/}
                            <Nps
                                nps={nps}
                                npsOptions={npsOptions}
                                onChange={setNps}
                                disabled={showDest}
                            />
                        </div>
                        {showDest && <div className={'external-review__wrapper'}>
                            <div className={cn('external-review')}>
                                <h3>Будем признательны, если Вы разместите этот отзыв на одной из площадок</h3>
                                <div className={'external-review__subtext'}>Выберите площадку, чтобы перейти на нее. Ваш
                                    отзыв уже скопирован - просто вставьте его в поле для отзыва на площадке.
                                </div>
                                {adplatforms && <ExternalReview
                                    utm={utm}
                                    comment={comment}
                                    adplatforms={adplatforms}/>
                                }
                            </div>

                        </div>}

                        {!showDest && <>
                            <div className={'button__wrapper'}>
                                <Button
                                    label="Оставить отзыв →"
                                    onClick={onSubmit}
                                />
                            </div>
                            <div className={cn('agreement')}>
                                <Agreement
                                    setAgreement={setAgreement}
                                    agreement={agreement}
                                    error={errors.agreement}
                                />
                            </div>
                        </>}
                    </div>
                    <FormFooter template={template} />

                </div>
            </div>
                <Footer template={template} />
            </div>
        </>
    );
}

export default MainForm;
