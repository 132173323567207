import React, { useEffect } from "react";
import cn from "classnames";
import '../styles/thanks.scss';
import thanks from '../images/thanks.png';
import Header from "./common/Header";
import {ClaimAcceptedText} from "./tmp_folder/Templates";

const ClaimAccepted = ({template = 'default'}) => {

    useEffect(()=>{
        document.querySelector('meta[property="og:title"]').content = 'ОК Ревью: спасибо за ваш отзыв!'
        document.querySelector('meta[property="og:description"]').content = 'ОК Ревью: спасибо за ваш отзыв!'
        document.title = 'ОК Ревью: спасибо за ваш отзыв!';
    }, []);

    return (
        <>
            <Header template={template}/>
            <div className={cn('container', 'thanks')}>
                <div className={cn('thanks__wrapper')}>
                    <div className={'thanks__image'}>
                        <img src={thanks} alt="" width="100%" />
                    </div>
                    <ClaimAcceptedText template={template}/>
                </div>
            </div>
            <div className={'footer'}>
                <div className={'footer__fake-block'} />
            </div>
        </>
    );
}
export default ClaimAccepted;
