import React, { useEffect, useState } from "react";
import cn from "classnames";
import { ProgressSpinner } from "primereact/progressspinner";
import ExternalReview from "components/common/ExternalReview";
import Footer from "components/common/Footer";
import { api, mainService } from "services";
import {getParam} from "./helpers";
import Header from "./common/Header";
import '../styles/main.scss';
import '../styles/templates.scss';
import { AdplatformsHeader } from "./tmp_folder/Templates";
import FormFooter from "./common/FormFooter";

const NoTextReminder = ({template = 'default'}) => {
    const utm = getParam();

    const [showDest, setShowDest] = useState(false);
    const [loading, setLoading] = useState(false);
    const [adplatforms, setAdplatforms] = useState([]);
    const [branch, setBranch] = useState('');

    const { destOptions } = mainService;

    useEffect(() => {
        if(utm) {
            api.getClientInfo(utm)
                .then((result) => {
                    result.data && setBranch(result.data.branch)
                })
        }

        document.querySelector('meta[property="og:title"]').content = 'Оставьте отзыв о компании'
        document.querySelector('meta[property="og:description"]').content = 'Оставьте отзыв о компании'
        document.title = 'Оставьте отзыв о компании';

        fetch(`${process.env.REACT_APP_API_URL}/review/${utm}`, { method: 'GET' })
            .then(response => response.json())
            .then(result => {
                let tmp = [];
                // eslint-disable-next-line array-callback-return
                if(result.data) {
                    result.data?.adplatforms.forEach(item => {
                        const tmp2 = destOptions.find((dest) => dest.platform === item.type);
                        if (typeof tmp2 !== 'undefined') {
                            tmp.push({
                                ...tmp2,
                                url: item.url
                            })
                        }
                    });

                    tmp.sort((a, b) => a.sort > b.sort ? 1 : -1 );

                    setAdplatforms(tmp);
                }

                setLoading(false);
                setShowDest(true);
            })

        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Header template={template}/>
            <div className={cn('bg__wrapper')}>

                <div className={cn('container')}>
                    <div className={cn('form')}>
                        <div className={cn('form__header')}>
                            <h1 className={cn('header', 'header__text')}>Оставьте отзыв<br/>о вашем визите</h1>
                        </div>
                        <div className={cn('form__container')}>
                            {loading && <div className={cn('loader')} ><ProgressSpinner/></div>}

                            {showDest && <div className={'external-review__wrapper'}>
                                <div className={cn('external-review')}>
                                    <AdplatformsHeader  template={template} branch={branch}/>
                                    {adplatforms && <ExternalReview
                                        utm={utm}
                                        comment={''}
                                        adplatforms={adplatforms} />
                                    }
                                </div>
                            </div>}
                            <div className={cn('question')}>
                                Возник вопрос? Наш контакт: <a href="mailto:info@okreview.ru">info@okreview.ru</a>
                            </div>
                        </div>
                        <FormFooter template={template} />
                    </div>
                </div>
                <Footer template={template} />
            </div>
        </>
    );

}

export default NoTextReminder;
