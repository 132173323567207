class Api {

    getClientInfo(utm = null) {
        if(!utm) return Promise.resolve("not fount utm");

        return fetch(`${process.env.REACT_APP_API_URL}/review/${utm}/client-info`, { method: 'GET' })
            .then(response => response.json())
            .then(result => result)
    }

}

const api = new Api();
export default api