import React, {useEffect} from "react";
import {YMInitializer} from 'react-yandex-metrika';
import {useLocation} from "react-router-dom";

const yaId = 78656880;

const hit = (pathname) => {
    if (yaId) {
        try {
            window[`yaCounter${yaId}`].hit(pathname);
        } catch (e) {
        }
    }
};

/**
 * @param {string} event
 */
export const reachGoal = (event) => {
    if (yaId) {
        try {
            window[`yaCounter${yaId}`].reachGoal(event);
        } catch (e) {
        }
    }
};

const usePageViews = () => {
    let location = useLocation();

    useEffect(
        () => {
            hit(location.pathname);
        },
        [location]
    );
};

const YandexMetrika = () => {
    usePageViews();

    return (
        <>
            {yaId && <div
                style={{'position': 'absolute', 'left': '-9999px'}}
            >
                <YMInitializer
                    accounts={[yaId]}
                    options={
                        {
                            clickmap: true,
                            trackLinks: true,
                            accurateTrackBounce: true,
                            webvisor: true,
                        }
                    }
                />
            </div>}
        </>
    );
}

export default YandexMetrika;
