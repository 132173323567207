import React, {useEffect, useState} from "react";
import cn from "classnames";
import '../styles/thanks.scss';
import {getThanksImage} from "../services/logo"
import Header from "./common/Header";
import {ClaimAcceptedText} from "./tmp_folder/Templates";

const Thanks = ({template = 'default'}) => {

    const [image, setImage] = useState(null);
    useEffect(() => {
        const image =  getThanksImage(template)
        setImage(image);
    }, []);

    return (
        <>
            <Header template={template}/>
            <div className={cn('bg__wrapper')}>
            <div className={cn('container', 'thanks')}>
                <div className={cn('thanks__wrapper')}>
                    <div className={'thanks__image'}>
                        {image && <img src={image.src} alt="" width="100%" /> }
                    </div>
                    <ClaimAcceptedText template={template}/>
                </div>
            </div>
                {template === 'default' && <div className={'footer'}>
                    <div className={'footer__fake-block'} />
                </div>}
            </div>
        </>
    );
}
export default Thanks;
